<template>
  <div class="w-full h-3 flex rounded-full" style="background-color: #F7F7FF;">
    <div :class="`h-3 rounded-full ${color}`" :style="`width: ${value}%`" />
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: {
      type: String,
      default: "0"
    }
  },
  computed: {
    color() {
      if (this.value < 30) {
        return "bg-desire";
      }
      if (this.value >= 30 && this.value < 100) {
        return "bg-carrotOrange";
      }
      if (this.value === 100) {
        return "bg-mediumSeaGreen";
      }
      return "";
    }
  }
};
</script>
